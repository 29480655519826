import React from "react"
import Img from "gatsby-image"
import {useStaticQuery, graphql} from "gatsby"
import { ImageFixedWrapper } from "../elements"


const ImageFixed = ({fixed}) => {
    const data = useStaticQuery(graphql`
        query {
            imageSharp(fixed: {originalName: { eq: "kinesiologas-anunciate-aqui.jpg" }}) {
                fixed {
                    ...GatsbyImageSharpFixed
                }
            } 
        }
    `)
    return <ImageFixedWrapper>
        <Img fixed={fixed ? fixed : data.imageSharp.fixed} style={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
        }} />        
    </ImageFixedWrapper>
}

export default ImageFixed
